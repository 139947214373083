import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { io } from "socket.io-client";
import AppContext from '../AppContext';

export const Layout = (props) => {
    const { children } = props;
    const [socket, setSocket] = useState(null);
    // setSocket(io(`http://${window.location.hostname}:3002`));
    useEffect(() => {
        setSocket(io(process.env.REACT_APP_SOCKET_URL));        
    }, [socket !== null]);
    return (
        <>
        <AppContext.Provider value={socket}>
        <Header />
        <Sidebar />
        <main id="main" className="main">
            { children }
        </main>
        <a onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
        
        </AppContext.Provider>
        <Helmet>
            {/* <script src="../../../resources/jquery/jquery-3.4.1.js"></script>
            <script src="../../../resources/bootstrap/popper.min.js"></script>
            <script src="../../../resources/bootstrap/bootstrap.min.js"></script>
            <script src="../../../resources/jquery/jquery-ui.js"></script>
            <script src="../../../assets/js/main.js"></script> */}
        </Helmet>
        
        </>
    )
}